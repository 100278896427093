/* @font-face {
  font-family: 'bpg_nino_elite_capsregular';
  src: url('/public/fonts/bpg_nino_elite_caps-webfont.woff2') format('woff2'),
       url('/public/fonts/bpg_nino_elite_caps-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
} */
html{
  overflow-x: hidden;
}
body{
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background-color: #070f1f;
  /*background-image: linear-gradient(#070f1f, hsl(220, 76%, 18%));*/
  /* background-image: linear-gradient(#2a2b1b, #d2cca7); */
  background-attachment: fixed;
  overflow-x: hidden;
}
.container{
    background: #131a1e;
    padding: 15px;
    border-radius: 10px;
}
.cl-white{
  color: #fff;
}
.pt-10{
  padding-top: 10px;
}
.pb-10{
  padding-bottom: 10px;
}
.pt-30{
  padding-top: 30px;
}
.pb-30{
  padding-bottom: 30px;
}
.p-0{
  padding: 0;
}
.mb-30{
  margin-bottom: 30px;;
}
.mt-10{
  margin-top: 10px;
}
.mt-40{
  margin-top: 40px;
}
.mt-25{
  margin-top: 25px;;
}
.main-logo{
  width: 150px;
}
.bold{
  font-weight: bold;
}
.default-title{
  color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 17px;
  font-weight: 100;
}
.title-lg{
  font-size: 23px;
}
.title-md{
  font-size: 15px;
  font-weight: 100;
}
.title-sm{
  font-size: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
.wrapper{
  max-width: 400px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
}
.personImage{
  max-width: 100px;
  border-radius: 50%;
  box-shadow: 0 5px 4px rgba(23,23,23,0.24);
}


.person-section{
    display: flex;
    justify-content: space-between;
}

.left{
  padding-left: 3px;
}

.right{
  padding-top: 0px;
  padding-bottom: 0px;
  text-align: center;
  margin-right: 36px;
  position: relative;
  top: -30px;
}
.language-dropdown{
  position: absolute;
  right: 2px;
  top: 6px;
}

/* Input styling*/
.tip-value{
  width: 100%;
  padding: 2px 0px 0px 0px;
  height: 55px;
  border: 2px solid #dd7e2c;
  border-radius: 10px;
  padding-left: 10px;
  font-size: 30px;
  color: #7d7b7b;
  background: #fff;
  box-sizing: border-box;
  box-shadow: 0 5px 3px rgba(23,23,23,0.24);
}
.reset-btn{
  padding-right: 10px;
  cursor: pointer;
  float: right;
  top: -47px;
  position: relative;
  border: none;
  background: transparent;
}
.tip-field{
  display: block;
}

textarea, input:focus{
  outline: none;
}
.title-section{
  font-weight: bold;
}

.reset-btn-image{
  width: 30px;
  margin-top: 5px;
}





/*Rating starts*/
.star {
  font-size: 40px;
  cursor: pointer;
  color: #ccc;
}

.star.filled {
  color: #dd7e2c;
}

.rating{
  display: flex;
  justify-content: center;
  
  padding-top: 15px;

}



/*Review buttons*/

input[type='radio'] {
  display: none;
}

label {
  padding: 10px;
  margin: 0 5px;
}

#good:checked + label {
  background-color: #028400;
  color: #fff;
  border: 1px solid #028400;
}


#bad:checked + label {
  background-color: #c44848;
  color: #fff;
  border: 1px solid #c44848;
}
#great:checked + label {
  background-color: #ffd700;
  color: #fff;
  border: 1px solid #ffd700;
}
.review-section{
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.review-btn{
  width: 95px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 6px;
  margin: 0 0 !important;
  font-size: 13px;
  color: #fff;
  background: #070f1f;
  /* box-shadow: 0 1px 3px rgba(23,23,23,0.24); */
  box-shadow: 0 1px 12px rgb(51 51 51 / 48%);
}

.review-text{
  width: 100%;
  margin-top: 12px;
  background: transparent;
  height: 100px;
  border-radius: 6px;
  border: 2px solid #dd7e2c;
  color: #131a1e;
  font-size: 17px;
  box-sizing: border-box;
  padding-left: 10px;
  padding-top: 5px;
  background: #fff;
  box-shadow: 0 4px 5px rgba(23,23,23,0.24);
}




/*Custom checkbox*/







.note{
  margin-left: 0px;
  font-size: 13px;
  top: -6px;
  position: relative;
  color: #ffffff;
  padding-left: 5px;
}


:root {
	--background: #03900d;
	--primary: #c55800;
	--secondary: #0176a2;
	--third: #87006c;
}
.shape {
  background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
    animation: morph 8s ease-in-out infinite;
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    height: 250px;
    transition: all 1s ease-in-out;
    width: 250px;
    position: absolute;
    right: -30px;
    top: -40px;
    z-index: -1;
}

@keyframes morph {
 0% {
     border-radius:  60% 40% 30% 70% / 60% 30% 70% 40%;
     background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
 } 
 
 50% {
     border-radius:  30% 60% 70% 40% / 50% 60% 30% 60%;
     background: linear-gradient(45deg, var(--third) 0%, var(--secondary) 100%);
 }
 
 100% {
   border-radius:  60% 40% 30% 70% / 60% 30% 70% 40%;
   background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
 } 
}




/*Payment buttons*/

.btn-group-pay{
  display: flex;
  max-width: 400px;
  justify-content: space-between;
  margin-top: 10px;
}
.gpay-btn{
  background: #fff;
  border: none;
  width: 100%;
  padding-top: 9px;
  box-shadow: 0 4px 4px rgba(23,23,23,0.24);
  border-radius: 6px;
  margin-top: 8px;
  cursor: pointer;
}
.gpay{
  max-width: 61px;
  width: 100%;
}
.apple-btn{
  background: #fff;
  border: none;
  width: 100%;
  padding-top: 9px;
  box-shadow: 0 4px 4px rgba(23,23,23,0.24);
  border-radius: 6px;
  margin-top: 8px;
  cursor: pointer;
}
.appPay{
  max-width: 53px;
  width: 100%;
  cursor: pointer;
}
.card-pay{
  width: 100%;
  font-size: 20px;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-top: 12px;
  background: #d07a2d;
  border: 1px solid #d07a2d;
  color: #fff;
  border-radius: 6px;
  box-shadow: 0 4px 4px rgba(23,23,23,0.24);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  cursor: pointer;
  transition: all .5s ease;
  -webkit-transition: all .5s ease;
}
.card-pay:hover, .card-pay:active,.card-pay:focus{
  background: #f0760a;
  border: 1px solid #f0760a;
}
.payment-btns{
  background: #2a2b1b5c;
  padding: 15px;
  background-attachment: fixed;
  /* margin: 0; */
  border-radius: 15px;
  margin-top: 10px;
}

.sum{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #fff;
  margin: 0;
  font-weight: 100;
}


.slide-enter {
  max-height: 0;
  overflow: hidden;
  transition: max-height 500ms ease-in-out;
}

.slide-enter-active {
  max-height: 100px; /* adjust this value to your needs */
}

.slide-exit {
  max-height: 100px; /* should be the same as in .slide-enter-active */
  transition: max-height 500ms ease-in-out;
}

.slide-exit-active {
  max-height: 0;
  overflow: hidden;
}
.language-switcher{
  text-align: right;
}
.language-switcher button{
  background: transparent;
  border: navajowhite;
  color: #fff;
  font-size: 22px;
}

.language-menu{
  background: #131a1e;
  width: 300px;
  text-align: center;
  padding-bottom: 20px;
  padding-top: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
  border-radius: 10px;
  color: #fff;
}
.close-button{
  float: right;
  top: -14px;
  position: absolute;
  right: -14px;
  background: #fff;
  border: 1px solid #070f1f;
  color: #070f1f;
  border-radius: 5px;
  width: 30px;
  height: 30px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 80%);
  z-index: 1000;
}
.lang-n{
  padding-top: 5px;
  padding-bottom: 5px;
}



.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 14px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #d07a2d;
}

input:focus + .slider {
  box-shadow: 0 0 1px #d07a2d;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.custom-checkbox{
  margin-top: 15px;
  margin-bottom: 15px;
}

.lang-switch-child{
  border-radius: 6px;
  margin: 0 0 !important;
  font-size: 16px;
  color: #fff;
  background: #070f1f;
  /* box-shadow: 0 1px 3px rgba(23,23,23,0.24); */
  box-shadow: 0 1px 12px rgb(51 51 51 / 48%);
  width: 140px;
  position: relative;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 10px !important;
  padding-top: 10px;
  padding-bottom: 10px;
}


#container {
  position: relative;
}
#numberInput {
  padding-right: 20px; /* Make room for the symbol */
}
#currencySymbol {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  pointer-events: none;
}
.currancySymbol{
  font-size: 30px;
  top: 37px !important;
  color: #7d7b7b;
}
.terms{
  color: #fff;
  text-decoration: none;
}